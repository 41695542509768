.kahani-modal-cta-button-container button {
  border: none;
  border-radius: 10px;
  background: #b0b9b6;
  color: white;
  font-size: 1.3em;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 30px;
}

.kahani-modal-cta-button-container {
  position: absolute;
  bottom: 50px;
  width: 35%;
  right: 40%;
  z-index: 100000;
}

.kahani-cta-button-container {
  display: flex;
}

.kahani-modal-story-title-container {
  position: absolute;
  top: 45px;
  left: 20px;
  width: 50%;
  text-align: left;
  vertical-align: middle;
  z-index: 9999;
}

.kahani-modal-story-title-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #b0b9b6;
  border: 2px solid #b0b9b6;
}

.kahani-modal-story-title {
  font-size: 1em;
  color: white;
  margin-left: 10px;
  display: inline-block;
  position: absolute;
  top: 10px;
  letter-spacing: -0.02em;
}

.kahani-modal-container-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(6px);
  z-index: 999999999999;
}

.kahani-modal-slider-placer {
  height: 100%;
  background: black;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
}

.kahani-modal-chapter {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.kahani-modal-chapter-view {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.kahani-modal-icon-share {
  position: absolute;
  bottom: 61px;
  right: 41px;
  margin: auto;
  width: 30px;
  height: 30px;
  z-index: 2147483645;
}

.kahani-modal-icon-muted {
  position: absolute;
  bottom: 61px;
  left: 41px;
  z-index: 2147483645;
  margin: auto;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.kahani-modal-close-overlay {
  appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  position: absolute;
  top: 40px;
  right: 20px;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  cursor: pointer;
  z-index: 9999;
  padding: 10px;
}

.kahani-modal-photo-animated-overlay1 {
  position: absolute;
  right: 8%;
  top: 20%;
  padding: 20px;
  border-radius: 8px;
  rotate: 12deg;
  transform: rotate(12deg);
  background-color: #ffa97d;
  color: white;
}

.kahani-modal-photo-animated-overlay2 {
  position: absolute;
  left: 10%;
  bottom: 30%;
  padding: 20px;
  border-radius: 8px;
  rotate: -3deg;
  transform: rotate(-3deg);
  background-color: #ffcb7d;
  color: white;
}

.kahani-modal-photo-animated-overlay-full {
  position: absolute;
  top: 20%;
  width: 76%;
  padding: 20px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.1em;
  backdrop-filter: blur(3px);
}

.kahani-modal-photo-animated-overlay-full p,
.kahani-modal-photo-animated-overlay-full strong,
.kahani-modal-photo-animated-overlay-full em {
  color: white;
}

.chapter_preview-container {
  position: relative;
  height: 100%;
}
