.CollectionCard {
    width: 400px;
    height: 325px;
    list-style-type: none;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}

.CollectionCard::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .05), 0 5px 15px rgba(0, 0, 0, .05);
    transform: rotate3d(70, 90, -20, 35deg);
    transition: all linear .15s;
    opacity: 0;
    background: rgba(200, 200, 200, .5);
}

.CollectionCard:hover::before {
    transform: none;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
}

.CollectionCard__link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.CollectionCard__album {
    width: 150px;
    height: 325px;
    position: relative;
    flex: 0 0 auto;
}

.CollectionCard__thumbnail {
    width: 100%;
    height: 100%;
}

.CollectionCard__thumbnail .Image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all cubic-bezier(.25, .95, .45, .95) .4s;
}

.CollectionCard__thumbnail .Image.thumb1 {
    transform: none;
}

.CollectionCard__thumbnail .Image.thumb2 {
    transform: scale(.98);
}

.CollectionCard__thumbnail .Image.thumb3 {
    transform: scale(.96);
}

.CollectionCard:hover .CollectionCard__thumbnail .Image {
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1), 0 5px 10px rgba(0, 0, 0, .2);
}

.CollectionCard:hover .CollectionCard__thumbnail .Image.thumb1 {
    transform: rotate(3deg) translate(25px, 0);
}

.CollectionCard:hover .CollectionCard__thumbnail .Image.thumb2 {
    transform: rotate(-3deg) translate(5px, 0);
}

.CollectionCard:hover .CollectionCard__thumbnail .Image.thumb3 {
    transform: rotate(-9deg) translate(-15px, 0);
}

.CollectionCard__details {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 40px;
    max-width: 170px;
}

.CollectionCard__callouts {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.CollectionCard__callout {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 20px;
}

.CollectionCard__callout__badge {
    display: inline-block;
    background: #ccc;
    width: 30px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    font-weight: 500;
}

.CollectionCard__callout__label {
    margin-left: 5px;
}

.CollectionCard__callout+.CollectionCard__callout {
    margin-top: 10px;
}