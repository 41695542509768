.kahani-thumbnail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
}

.kahani-thumbnail {
  margin-right: 5px;
  margin-left: 5px;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  border: 2px solid var(--kahani-primary_color, #b0b9b6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.kahani-thumbnail img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: 1px solid white;
}

.kahani-thumbnail-label {
  text-align: center;
  display: block;
  margin-top: 3px;
  font-family: Helvetica, sans-serif;
  font-family: var(--kahani-body_font, Helvetica, sans-serif);
}

.kahani-thumbnails-flex-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: auto;
  animation: 1.5s ease-in-out 0s 1 slideInFromLeft;
  overflow: hidden;
  /* min-height: 127px; */
}
