#main-menu .MuiListItemIcon-root {
  margin-right: 16px;
  margin-left: 16px;
  min-width: 0;
}

#main-menu .storyblocks-tab-link .MuiButtonBase-root::before {
  content: url("../../../public/img/collections-icon.svg");
  margin-left: 16px;
  opacity: 0.54;
  position: relative;
  top: 2px;
}

#main-menu .storyblocks-tab-link.dark .MuiButtonBase-root::before {
  filter: invert(1);
}

#main-menu .storyblocks-tab-link .MuiListItemIcon-root {
  margin-left: 0px;
}

#main-menu .MuiListSubheader-root {
  font-size: 12px;
  font-weight: bold;
  padding-left: 32px;
}

/* collapsed styling */
#main-menu.collapsed .MuiListSubheader-root,
#main-menu.collapsed .MuiListItemText-root,
#main-menu.collapsed .storyblocks-tab-link .MuiListItemIcon-root,
#main-menu.collapsed .MuiAlert-message,
#main-menu.collapsed .build-text,
#main-menu.collapsed .activate-plan-nav-text {
  display: none;
}

#main-menu.collapsed .MuiListItemIcon-root,
#main-menu.collapsed .storyblocks-tab-link .MuiButtonBase-root::before,
#main-menu.collapsed .MuiAlert-icon {
  margin: auto;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  margin: 0;
}

#main-menu.collapsed .MuiListItem-root,
#main-menu.collapsed .storyblocks-tab-link .MuiListItem-root {
  justify-content: center;
}

#main-menu.collapsed .Mui-selected .MuiListItemIcon-root,
#main-menu.collapsed
  .storyblocks-tab-link
  .Mui-selected.MuiButtonBase-root::before {
  background-color: rgba(122, 87, 209, 0.1);
  border-radius: 12px;
  /* padding: 7px 0; */
}

#main-menu.collapsed
  .storyblocks-tab-link
  .Mui-selected.MuiButtonBase-root::before {
  padding-bottom: 0;
}

#main-menu.collapsed .storyblocks-tab-link .Mui-selected.MuiButtonBase-root {
  padding: 7px 0;
}

#main-menu.collapsed .Mui-selected {
  background-color: transparent;
}
