.list-view-container {
        width: 100%;
        flex-direction: row;
        display: flex;
        margin: 20px;
        padding: 10px;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.list-view-cta {
    margin-left: auto;
    order: 2;
}

.list-switch-icon {
    cursor: pointer;
}

.toggle-view-icon {
    float: right;
    margin: 10;
    cursor: pointer;
}