.uploadcare--widget__button_type_open,
.uploadcare--button_primary {
    background-color: rgba(85, 60, 146);
    color: #fff;
    cursor: pointer;
    border-color: rgba(85, 60, 146);
    border-radius: 24px;
}

.uploadcare--widget__button_type_open:hover,
.uploadcare--button_primary:hover,
.uploadcare--button_primary:focus,
.uploadcare--widget__button_type_open:focus {
    background-color: rgb(104, 84, 153);
    color: #fff;
    cursor: pointer;
    border-color: rgba(85, 60, 146);
}

.uploadcare--menu__item:hover {
    color: rgb(104, 84, 153);
    cursor: pointer;
}

.uploadcare--file_status_uploading .uploadcare--file__preview,
.uploadcare--progressbar__value,
.uploadcare--progress_type_canvas {
    background-color: rgb(104, 84, 153);
    border-color: rgb(104, 84, 153);
}

.uploadcare--crop-sizes__item {
    overflow: visible;
}

.uploadcare--crop-sizes__item:nth-child(1)::after {
    content: "Free form";
}

.uploadcare--crop-sizes__item:nth-child(2)::after {
    content: "Square";
}

.uploadcare--crop-sizes__item:nth-child(3)::after {
    content: "Portrait";
}

.uploadcare--files.uploadcare--files_type_table::after {
    /* content: "Click on your uploaded image to access the crop tool!"; */
    font-weight: bold;
    padding: 1em;
    margin: 2em;
    background-color: #4005a994;
    color: white;
    border-radius: 5px;
    width: 70%;
    display: flex;
    align-self: center;
    text-align: center;
    justify-content: center;
}

.uploadcare--files__item {
    cursor: pointer;
}

.uploadcare--preview__back {
    border: 1px solid #553c92;
    color: #553c92;
}