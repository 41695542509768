.Image {
  position: relative;
  overflow: hidden;
}

.Image__ratioSizer {
  height: 0;
  padding-top: 100%;
  overflow: hidden;
}

.Image__ratioSizer img,
.Image__ratioSizer .img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
